import $ from "jquery";
import "what-input";

// Foundation JS relies on a global variable. In ES6, all imports are hoisted
// to the top of the file so if we used `import` to import Foundation,
// it would execute earlier than we have assigned the global variable.
// This is why we have to use CommonJS require() here since it doesn't
// have the hoisting behavior.
window.jQuery = $;
require("foundation-sites");

// If you want to pick and choose which modules to include, comment out the above and uncomment
// the line below
//import './lib/foundation-explicit-pieces';
import "./lib/jquery.cookie";

$(document).foundation();

$(document).ready(function() {
  if ($("#page").hasClass("titleSection")) {
    if ($.cookie("modal_shown") == null) {
      $.cookie("modal_shown", "yes", { expires: 7, path: "/" });
      $("#trailerModal").foundation("open");
      $(".youtube iframe").attr(
        "src",
        "https://www.youtube.com/embed/5Ob8WRuDo2w"
      );
      $(document).on("open.zf.reveal", "#trailerModal", function() {
        $(".youtube iframe").attr(
          "src",
          "https://www.youtube.com/embed/5Ob8WRuDo2w"
        );
      });
      $(document).on("closed.zf.reveal", "#trailerModal", function() {
        $(".youtube iframe").attr("src", "");
      });
    } else {
      $(document).on("open.zf.reveal", "#trailerModal", function() {
        $(".youtube iframe").attr(
          "src",
          "https://www.youtube.com/embed/5Ob8WRuDo2w"
        );
      });
      $(document).on("closed.zf.reveal", "#trailerModal", function() {
        $(".youtube iframe").attr("src", "");
      });
    }
  } else {
    $(document).on("open.zf.reveal", "#trailerModal", function() {
      $(".youtube iframe").attr(
        "src",
        "https://www.youtube.com/embed/5Ob8WRuDo2w"
      );
    });
    $(document).on("closed.zf.reveal", "#trailerModal", function() {
      $(".youtube iframe").attr("src", "");
    });
  }
});
